import React from "react";
import styled from "styled-components";
import { Switch, useRouteMatch } from "react-router";
import { SentryRoute } from "ee/AppRouter";
import {
  APP_LIBRARIES_EDITOR_PATH,
  APP_SETTINGS_EDITOR_PATH,
  CUSTOM_EVENT_LIST_PATH,
  CUSTOM_FIELD_LIST_PATH,
  CUSTOM_OBJECT_LIST_PATH,
  DATA_SOURCES_EDITOR_ID_PATH,
  DATA_SOURCES_EDITOR_LIST_PATH,
  INTEGRATION_EDITOR_PATH,
  SAAS_GSHEET_EDITOR_ID_PATH,
  WORKFLOW_EDITOR_LIST_PATH,
} from "constants/routes";
import AppSettingsPane from "./AppSettings";
import DataSidePane from "./DataSidePane";
import LibrarySidePane from "./LibrarySidePane";
import EditorPane from "../EditorPane";
import WorkflowPane from "./WorkflowPane";
import CustomObjectPane from "./CustomObjectPane";
import CustomFieldPane from "./CustomFieldPane";
import CustomEventPane from "./CustomEventPane";

export const LeftPaneContainer = styled.div<{ showRightBorder?: boolean }>`
  height: 100%;
  border-right: ${({ showRightBorder = true }) =>
    showRightBorder ? "1px solid var(--ads-v2-color-border)" : "none"};
  background: var(--ads-v2-color-bg);
  overflow: hidden;
`;

const LeftPane = () => {
  const { path } = useRouteMatch();

  return (
    <LeftPaneContainer showRightBorder={false}>
      <Switch>
        <SentryRoute
          component={DataSidePane}
          exact
          path={[
            `${path}${DATA_SOURCES_EDITOR_LIST_PATH}`,
            `${path}${DATA_SOURCES_EDITOR_ID_PATH}`,
            `${path}${INTEGRATION_EDITOR_PATH}`,
            `${path}${SAAS_GSHEET_EDITOR_ID_PATH}`,
          ]}
        />
        <SentryRoute
          component={LibrarySidePane}
          exact
          path={`${path}${APP_LIBRARIES_EDITOR_PATH}`}
        />
        <SentryRoute
          component={AppSettingsPane}
          exact
          path={`${path}${APP_SETTINGS_EDITOR_PATH}`}
        />
        <SentryRoute component={EditorPane} />
        <SentryRoute
          component={WorkflowPane}
          exact
          path={`${path}${WORKFLOW_EDITOR_LIST_PATH}`}
        />
        {/*<SentryRoute*/}
        {/*  component={RulesPane}*/}
        {/*  exact*/}
        {/*  path={`${path}${RULES_EDITOR_LIST_PATH}`}*/}
        {/*/>*/}
        <SentryRoute
          component={CustomEventPane}
          exact
          path={`${path}${CUSTOM_EVENT_LIST_PATH}`}
        />
        <SentryRoute
          component={CustomFieldPane}
          exact
          path={`${path}${CUSTOM_FIELD_LIST_PATH}`}
        />
        <SentryRoute
          component={CustomObjectPane}
          exact
          path={`${path}${CUSTOM_OBJECT_LIST_PATH}`}
        />
      </Switch>
    </LeftPaneContainer>
  );
};

export default LeftPane;
