import { ZAvailableExtensions } from "ee/types/zuora";
import React from "react";
import { isInDevEnvironment } from "utils/isInDevEnvironment";

export function configureComponentManager(componentType: ZAvailableExtensions) {
  const defaultTarget = `https://staging2.zuora.com/platform/apps/extension-studio/component-manager/${componentType}`;
  const testDriveUrl = sessionStorage.getItem(
    "appsmith:appBuilderTestDriveLink",
  );
  const importMapOverrideEl = document.querySelector(
    "import-map-overrides-full",
  );
  const target =
    testDriveUrl && importMapOverrideEl
      ? defaultTarget
      : testDriveUrl || defaultTarget;

  return (
    <iframe
      id="platform-ui-extension-studio-component-manager"
      src={
        isInDevEnvironment
          ? `http://localhost:6006/?path=/story/platform-extension-studio-component-manager--${getStorybookPath(componentType)}`
          : target
      }
      style={{ width: "100%", height: "100%" }}
      title={getFrameTitle(componentType)}
    />
  );
}

function getFrameTitle(componentType: ZAvailableExtensions) {
  switch (componentType) {
    case ZAvailableExtensions.CustomEvent:
      return "Custom Event Editor";
    case ZAvailableExtensions.CustomField:
      return "Custom Field Editor";
    case ZAvailableExtensions.CustomObject:
      return "Custom Object Editor";
    case ZAvailableExtensions.Workflow:
      return "Workflow Editor";
  }
}

function getStorybookPath(componentType: ZAvailableExtensions) {
  switch (componentType) {
    case ZAvailableExtensions.CustomEvent:
      return "custom-events";
    case ZAvailableExtensions.CustomField:
      return "custom-fields";
    case ZAvailableExtensions.CustomObject:
      return "custom-objects";
    case ZAvailableExtensions.Workflow:
      return "workflows";
  }
}
