import {
  resetCustomEventLoadStatus,
  resetCustomEventSaveStatus,
  resetCustomFieldLoadStatus,
  resetCustomFieldSaveStatus,
  resetObjectComponentLoadStatus,
  resetObjectComponentSaveStatus,
  resetWorkflowLoadStatus,
  resetWorkflowSaveStatus,
  setIsComponentManagerLoaded,
} from "ee/actions/zuoraActions";
import {
  type PersistentZObjectDefinition,
  ZAvailableExtensions,
  type ZCustomEvent,
  type ZCustomFieldDefinition,
  type ZWorkflow,
} from "ee/types/zuora";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ZComponentPersistenceContext } from "ZComponentPersistenceProvider";
import { ZPostMessageContext } from "ZPostMessageProvider/ZPostMessageProvider";
import { showZuoraToast } from "ZToast/ZToast.actions";
import { configureComponentManager } from "../componentManagerUtils";

export const useZuoraComponentPage = (
  componentType: ZAvailableExtensions,
  selectedComponents: unknown[] | null,
  componentManagerIsLoaded: boolean,
  lastSaveAttemptSuccessful: boolean | null,
  lastLoadAttemptSuccessful: boolean | null,
) => {
  const dispatch = useDispatch();
  const { loadComponents } = useContext(ZComponentPersistenceContext);
  const { addMessageToQueue } = useContext(ZPostMessageContext);

  const resetSaveStatus = () => {
    switch (componentType) {
      case ZAvailableExtensions.Workflow:
        dispatch(resetWorkflowSaveStatus());
        break;

      case ZAvailableExtensions.CustomEvent:
        dispatch(resetCustomEventSaveStatus());
        break;

      case ZAvailableExtensions.CustomField:
        dispatch(resetCustomFieldSaveStatus());
        break;

      case ZAvailableExtensions.CustomObject:
        dispatch(resetObjectComponentSaveStatus());
        break;

      default:
    }
  };

  const resetLoadStatus = () => {
    switch (componentType) {
      case ZAvailableExtensions.Workflow:
        dispatch(resetWorkflowLoadStatus());
        break;

      case ZAvailableExtensions.CustomEvent:
        dispatch(resetCustomEventLoadStatus());
        break;

      case ZAvailableExtensions.CustomField:
        dispatch(resetCustomFieldLoadStatus());
        break;

      case ZAvailableExtensions.CustomObject:
        dispatch(resetObjectComponentLoadStatus());
        break;

      default:
    }
  };

  const getComponentIds = (components: unknown[]) => {
    switch (componentType) {
      case ZAvailableExtensions.Workflow:
        return (components as ZWorkflow[]).map(
          // the id must be a number or it will break table selection.
          (workflow: ZWorkflow) => +workflow.workflow_definition.id,
        );

      case ZAvailableExtensions.CustomEvent:
        return (components as ZCustomEvent[]).map(
          (event: ZCustomEvent) => event.id,
        );

      case ZAvailableExtensions.CustomField:
        return (components as ZCustomFieldDefinition[]).map(
          (field: ZCustomFieldDefinition) => field.id,
        );

      case ZAvailableExtensions.CustomObject:
        return (components as PersistentZObjectDefinition[]).map(
          (object: PersistentZObjectDefinition) => object.type,
        );

      default:
        return [];
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setIsComponentManagerLoaded(false));
      resetSaveStatus();
      resetLoadStatus();
    };
  }, []);

  useEffect(() => {
    loadComponents(componentType);
  }, []);

  useEffect(() => {
    if (selectedComponents?.length && lastSaveAttemptSuccessful !== null) {
      const payload = getComponentIds(selectedComponents);

      addMessageToQueue({
        type: "SAVE_COMPONENTS_SUCCESS",
        payload,
      });
    }
  }, [lastSaveAttemptSuccessful, selectedComponents]);

  useEffect(() => {
    if (!componentManagerIsLoaded) {
      return;
    }

    if (lastLoadAttemptSuccessful === false) {
      dispatch(showZuoraToast("Failed to get selected components"));
    }
    resetLoadStatus();
  }, [componentManagerIsLoaded, lastLoadAttemptSuccessful]);

  useEffect(() => {
    if (!componentManagerIsLoaded) {
      return;
    }

    if (lastSaveAttemptSuccessful) {
      dispatch(showZuoraToast("Components saved successfully"));
    } else if (lastSaveAttemptSuccessful === false) {
      dispatch(showZuoraToast("Failed to save changes", { kind: "error" }));
    }
    resetSaveStatus();
  }, [componentManagerIsLoaded, lastSaveAttemptSuccessful]);

  useEffect(() => {
    if (selectedComponents) {
      const payload = {
        selectedComponents: getComponentIds(selectedComponents),
      };

      addMessageToQueue({
        type: "MARK_SELECTED_COMPONENTS",
        payload,
      });
    }
  }, [selectedComponents]);

  return configureComponentManager(componentType);
};
