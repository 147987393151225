import type { AppState } from "ee/reducers";
import { ZAvailableExtensions, type ZWorkflow } from "ee/types/zuora";
import { useSelector } from "react-redux";
import { useZuoraComponentPage } from "../hooks/useZuoraComponentPage";

const WorkflowPage = () => {
  const workflowType = ZAvailableExtensions.Workflow;

  const selectedWorkflows: ZWorkflow[] | null = useSelector(
    (state: AppState) => state.ui.zuoraComponents[workflowType].data,
  );

  const componentManagerIsLoaded: boolean = useSelector(
    (state: AppState) => state.ui.zuoraComponents.componentManagerIsLoaded,
  );

  const lastSaveAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[workflowType].lastSaveAttemptSuccessful,
  );

  const lastLoadAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[workflowType].lastLoadAttemptSuccessful,
  );

  const page = useZuoraComponentPage(
    workflowType,
    selectedWorkflows,
    componentManagerIsLoaded,
    lastSaveAttemptSuccessful,
    lastLoadAttemptSuccessful,
  );

  return page;
};

export default WorkflowPage;
