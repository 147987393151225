import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { combinedPreviewModeSelector } from "../../../selectors/editorSelectors";
import { protectedModeSelector } from "selectors/gitSyncSelectors";
import { IDE_HEADER_HEIGHT } from "../../../IDE";
import { BOTTOM_BAR_HEIGHT } from "../../../components/BottomBar/constants";
import {
  DISABLED_CALLOUT_HEIGHT,
  PROTECTED_CALLOUT_HEIGHT,
} from "../IDE/ProtectedCallout";
import { getIsAppUneditable } from "ee/selectors/applicationSelectors";

interface EditorWrapperContainerProps {
  children: React.ReactNode;
}

const Wrapper = styled.div<{
  isProtectedMode: boolean;
  isForkedUneditable: boolean;
}>`
  display: flex;
  height: calc(
    100vh - 15px - ${IDE_HEADER_HEIGHT}px - ${BOTTOM_BAR_HEIGHT}px -
      ${(props) => {
        if (props.isForkedUneditable) {
          return `${DISABLED_CALLOUT_HEIGHT}px`;
        } else if (props.isProtectedMode) {
          return `${PROTECTED_CALLOUT_HEIGHT}px`;
        } else {
          return "0px";
        }
      }}
  );
  background-color: ${(props) => props.theme.appBackground};
`;

function EditorWrapperContainer({ children }: EditorWrapperContainerProps) {
  const isCombinedPreviewMode = useSelector(combinedPreviewModeSelector);
  const isProtectedMode = useSelector(protectedModeSelector);
  const isForkedUneditable = useSelector(getIsAppUneditable);

  return (
    <Wrapper
      className={classNames({
        [`relative w-full overflow-x-hidden`]: true,
        "select-none": !isCombinedPreviewMode,
      })}
      isForkedUneditable={isForkedUneditable}
      isProtectedMode={isProtectedMode}
    >
      {children}
    </Wrapper>
  );
}

export default EditorWrapperContainer;
